<template>
  <div class="item_container">
    <div class="title">
      <span v-if="this.data.year">{{ this.data.year }}年度</span>好建议
    </div>
    <div class="title_2">
      <el-divider>{{ data.title }}排行榜</el-divider>
    </div>
    <table class="rank_table">
      <tr class="head">
        <td>排名</td>
        <td>{{ data.col1Text }}</td>
        <td>{{ data.col2Text }}</td>
      </tr>
      <tr v-for="(item, index) in dataList" :key="index">
        <td>
          <span :class="'r' + (index + 1)">{{ index + 1 }}</span>
        </td>
        <td>{{ item[data.col1Code] }}</td>
        <td>{{ item[data.col2Code] }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataList: null
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      var list = this.data.list.sort((a, b) => {
        var x = a[this.data.col2Code]
        var y = b[this.data.col2Code]
        return x > y ? -1 : x > y ? 1 : 0
      })
      this.dataList = list.filter((a, i) => i < 10)
    }
  }
}
</script>

<style lang="less" scoped>
.item_container {
  .title {
    margin: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    span {
      padding: 10px;
      color: rgb(134, 115, 6);
    }
  }
  .title_2 {
    margin: 30px 0 30px 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    .el-divider__text {
      padding: 5px 20px;
      background-color: rgb(35, 67, 85);
      color: #fff;
    }
  }

  .rank_table {
    width: 100%;
    color: #fff;
    text-align: center;
    tr {
      line-height: 35px;
    }
    tr.head {
      background-color: rgba(35, 67, 85, 0.5);
    }
    td {
      span {
        display: inline-block;
        height: 25px;
        width: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 50px;
      }
      span.r1 {
        background-color: rgb(218, 178, 115);
      }
      span.r2 {
        background-color: rgb(233, 233, 216);
        color: #000;
      }
      span.r3 {
        background-color: rgb(186, 110, 64);
      }
    }
  }
}
</style>
